<template>
  <div>
    <h2
      data-cy="site_type_first"
      v-if="latticeType === '' || latticeType === null"
      class="text"
    >
      Please select a Site Type first!
    </h2>
    <v-form ref="form_sites" v-model="valid_site" v-else>
      <!-- site name -->
      <v-row>
        <v-col cols="4">
          <v-sheet data-cy="name" class="text" height="20px">Name</v-sheet>
        </v-col>
        <v-col cols="8">
          <v-text-field
            data-cy="site_name"
            dense
            label
            v-model="name"
            :rules="rules_sites"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- site position -->
      <v-row>
        <v-col cols="4">
          <v-sheet class="text">
            Position
            <InfoDialog info="position"> </InfoDialog>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-text-field
            data-cy="position_x"
            dense
            type="number"
            label="x"
            v-model.number="x"
            :rules="rules_sites"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            data-cy="position_y"
            dense
            type="number"
            label="y"
            v-model.number="y"
            :rules="rules_sites"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- magnetig field form for spins -->
      <div v-if="latticeType.includes('spins')">
        <v-sheet color="darkGrey--text">
          Magnetic Field
          <InfoDialog info="magField"></InfoDialog>
        </v-sheet>
        <v-row>
          <v-col cols="4">
            <v-text-field
              data-cy="bx"
              dense
              type="number"
              label="Bx"
              v-model.number="Bx"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              data-cy="by"
              dense
              type="number"
              label="By"
              v-model.number="By"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              data-cy="bz"
              dense
              type="number"
              label="Bz"
              v-model.number="Bz"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <!-- onsite energy for spinless fermions -->
      <v-row v-if="latticeType.includes('spinless')">
        <v-col cols="4">
          <v-sheet color="darkGrey--text" height="20px">Onsite Energy</v-sheet>
        </v-col>
        <v-col cols="4">
          <v-text-field
            type="number"
            data-cy="e0"
            dense
            label
            v-model.number="e0"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- tabs for magnetic field or spin channel for spinful fermions -->
      <div v-if="latticeType.includes('spinful')">
        <v-tabs class="my-15">
          <!-- magnetic field tab -->
          <v-tab data-cy="magnetic_field_tab" @click="calculateMagneticField">
            Magnetic Field Notation</v-tab
          >
          <v-tab-item class="mt-10">
            <v-row class="ml-1">
              <v-sheet class="text mb-10"
                >Energy in Magnetic Field Notation</v-sheet
              >
              <InfoDialog info="magFieldNotation"></InfoDialog>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  data-cy="e0"
                  dense
                  label="Onsite Energy"
                  v-model.number="e0"
                  :rules="rules_sites"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  data-cy="bx"
                  dense
                  type="number"
                  label="Bx"
                  v-model.number="Bx"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  data-cy="by"
                  dense
                  type="number"
                  label="By"
                  v-model.number="By"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  data-cy="bz"
                  dense
                  type="number"
                  label="Bz"
                  v-model.number="Bz"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- spin channel tab -->
          <v-tab data-cy="spin_channel_tab" @click="calculateSpinChannel">
            Spin Channel Notation</v-tab
          >
          <v-tab-item class="mt-10">
            <v-row class="ml-1">
              <v-sheet class="text mb-10"
                >Energy in Spin Channel Notation</v-sheet
              >
              <InfoDialog info="spinChannel"></InfoDialog>
            </v-row>
            <v-row>
              <v-col cols="1">
                <v-sheet class="text text-h5">↑↑</v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="e_uu"
                  dense
                  type="number"
                  label="↑↑"
                  v-model="e_uu"
                ></v-text-field>
              </v-col>
              <v-col cols="1"></v-col>
              <!-- ↑↓ complex number -->
              <v-col cols="1"><v-sheet class="text text-h5">↑↓</v-sheet></v-col>
              <v-col cols="2"
                ><v-text-field
                  data-cy="e_ud_r"
                  type="number"
                  dense
                  label="real"
                  v-model="e_ud_r"
                ></v-text-field>
              </v-col>
              <v-col cols="2"
                ><v-text-field
                  data-cy="e_ud_i"
                  type="number"
                  dense
                  label="imaginary"
                  v-model="e_ud_i"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1"><v-sheet class="text text-h5">↓↑</v-sheet></v-col>
              <v-col cols="4">
                <v-sheet class="text">
                  Complex Conjugation of <span class="text-h5">↑↓</span>
                </v-sheet>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="1"><v-sheet class="text-h5">↓↓</v-sheet></v-col>
              <v-col cols="4"
                ><v-text-field
                  data-cy="e_dd"
                  type="number"
                  dense
                  label="↓↓"
                  v-model="e_dd"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
        <!-- onsite_U: general values for fermion sites -->
        <v-row>
          <!-- <v-col cols="2">
            <v-sheet class="text">Onsite U</v-sheet>
          </v-col>
          <v-col cols="1" class="ml-n15">
            <InfoDialog info="onsiteU"></InfoDialog>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="number"
              data-cy="onsite_U"
              dense
              label="Onsite U"
              v-model.number="onsite_U"
              :rules="rules_sites"
            ></v-text-field>
          </v-col> -->
          <!-- onsite_BCS: general value for fermion sites -->
          <v-col cols="2">
            <v-sheet color="darkGrey--text" height="20px" class="text"
              >Onsite BCS</v-sheet
            >
          </v-col>
          <v-col cols="1" class="ml-n5">
            <InfoDialog info="onsiteBCS"></InfoDialog>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="number"
              data-cy="onsite_BCS_r"
              dense
              label="real"
              v-model.number="onsite_BCS_r"
              :rules="rules_sites"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="number"
              data-cy="onsite_BCS_i"
              dense
              label="imaginary"
              v-model.number="onsite_BCS_i"
              :rules="rules_sites"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <SuccessAlert
            v-if="showAlert"
            class="alert"
            :type="alertType"
            :message="alertMessage"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <!-- checkbox to hold form open for another site creation -->
      <v-row>
        <v-spacer />
        <v-col cols="4">
          <v-checkbox
            v-if="title === 'Add'"
            data-cy="add_more_sites"
            class="pl-3"
            dense
            v-model="addOneMore"
            label="add one more"
            color="hqsOrange"
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- form actions -->
      <v-row>
        <v-spacer />
        <v-col cols="4">
          <v-btn text color="darkGrey" @click="resetSiteForm">Clear</v-btn>
          <v-btn
            data-cy="apply"
            type="submit"
            rounded
            :dark="valid_site"
            depressed
            color="hqsOrange"
            @click.prevent="submitSite"
            :disabled="!valid_site"
            >Apply</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const SuccessAlert = () =>
  import(/* webpackChunkName: "alerts" */ '../general/SuccessAlert.vue')
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
import utils from '../../appUtils/utils'
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    InfoDialog,
    SuccessAlert,
  },
  data: () => ({
    /* form */
    valid_site: false,
    rules_sites: [(v) => !!v || v === 0 || 'Required'],
    /* SuccessAlert */
    showAlert: false,
    alertType: '',
    alertMessage: '',
  }),

  props: {
    title: String,
  },

  methods: {
    ...mapMutations('lattice', [
      'applyAddedSite',
      'applyEditedSite',
      'resetSiteID',
      'updatePlot',
      'setHasLattice',
      'setMagneticField',
      'setSpinChannel',
      'resetAddOneMore',
    ]),

    calculateMagneticField() {
      let channel = {
        e_uu: this.e_uu,
        e_dd: this.e_dd,
        e_ud_r: this.e_ud_r,
        e_ud_i: this.e_ud_i,
      }
      let field = utils.calculateMagneticField(channel)
      this.setMagneticField(field)
    },

    calculateSpinChannel() {
      let field = { e0: this.e0, Bx: this.Bx, By: this.By, Bz: this.Bz }
      let channel = utils.calculateSpinChannel(field)
      this.setSpinChannel(channel)
    },

    submitSite() {
      this.showError = false
      try {
        if (this.title === 'Add') {
          this.applyAddedSite()
        } else if (this.title === 'Edit') {
          this.applyEditedSite()
          this.resetSiteID()
        }
        this.prepareAlert('Site applied successfully!', 'success')
      } catch (error) {
        this.prepareAlert('Application failed!', 'error')
      }
      this.$emit('closeDialogOnEdit', this.title)
      this.$refs.form_sites.resetValidation()
      if (!this.hasLattice) {
        this.setHasLattice()
      }
      this.updatePlot()
    },

    resetSiteForm() {
      this.$refs.form_sites.reset()
    },

    prepareAlert(message, type) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },
  },

  computed: {
    ...mapState('lattice', {
      sites: (state) => state.sites,
      editSiteIndex: (state) => state.editSiteIndex,
      hasLattice: (state) => state.hasLattice,
      latticeType: (state) => state.latticeType,
    }),

    ...mapState('backend', {
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapFields('lattice', [
      'currentSite.name',
      'currentSite.position.x',
      'currentSite.position.y',
      'currentSite.Bx',
      'currentSite.By',
      'currentSite.Bz',
      'currentSite.e0',
      'currentSite.onsite_U',
      'currentSite.onsite_BCS_r',
      'currentSite.onsite_BCS_i',
      'spinChannel.e_uu',
      'spinChannel.e_dd',
      'spinChannel.e_ud_r',
      'spinChannel.e_ud_i',
      'addOneMore',
    ]),
  },

  watch: {
    editSiteIndex() {
      if (this.editSiteIndex == -1 || this.editSiteIndex == '') {
        try {
            this.$refs.form_sites.resetValidation()
        } catch (error) {
            console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>
.alert {
  margin: auto;
}
</style>
